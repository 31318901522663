@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf');
  // font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Semi-Bold';
  src: url('../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf'); //Updated the proper bold styles for the Roboto Font previously it was RobotoCondensed
  font-weight: 600;
  font-variation-settings: 'wght' 600; //
  font-style: normal;
}

// 40px  -> 1.2rem 	19.2px 		- Headings
// 32px  -> 1.1rem 	17.6px		- General Subheading
// 26px(20px,24px)    -> 1.0rem		16px 		- General Titles
// 18px  -> 0.9rem     	14px		- General font size - below 600 (0.75rem)
