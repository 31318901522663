$RobotoReg: 'Roboto-Regular';
$RobotoSemiBold: 'Roboto-Semi-Bold';
$RobotoBold: 'Roboto-Bold';

// colors
$colorGrey: #646e81;
$matOptionHoverColor: #d1d4d982;
$borderColor: #646e81;
$btnColor: #5a2a82;
$sheenColor: #23a4ad;
$yellowColor: #fdb71a;
$greenColor: #59b753;
$redColor: #d40000;
$commonBlack: #333333;
$commanWhite: #ffffff;
$lightGrey: #e6eaed;
$sliderGray: #e5e5e8;
$paleWhite: #efeff2;
$paleGrey: #efeff2;
$black: #000000;
$ghostWhite: #f6f6f9;
$gunPowder: #49454f;
$sparklingForst: #d1d4d9;
$errorColor: #c4314b;
$commonBlue: #41a2d0;
$fordGray: #979797;
$backGroundColor: #f0efef;
$onHoverBlack: #444444;
$scrollBg: rgba(170, 170, 170, 0.6);
$disable: #ccc;
$orangeColor: #fcb045;

// font sizes
$fs: 18px;
