@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@angular/cdk/overlay-prebuilt.css';
@import '@angular/material/theming';
@import '~material-icons/iconfont/material-icons.css';
@import './assets/scss/fonts';
@import './assets/scss/variables';
@import './assets/scss/mixins';

@include mat-core();

$mat-deep-purple-snps: (
  50: #ede7f6,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: #673ab7,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #3e2652,
  A100: #b388ff,
  A200: #7c4dff,
  A400: #651fff,
  A700: #6200ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$app-primary: mat-palette($mat-grey, 900);
$app-accent: mat-palette($mat-deep-purple-snps, 900);
$app-warn: mat-palette($mat-deep-orange);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

$background: #fff;
$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);

.spacer {
  flex: 1 1 auto;
}

html,
body,
app-root,
.app-frame {
  overflow: hidden;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-family: $RobotoReg;
}
body {
  background-color: $ghostWhite;
  overflow: hidden;
}
.restrict_width {
  max-width: 1920px;
  margin: 0 auto !important;
  padding-top: 20px !important;
}
.app-frame {
  overflow-y: hidden;
}

.material-icons.color_primary {
  color: $primary;
}

.material-icons.color_accent {
  color: $accent;
}

h3,
p {
  margin: 0;
}

// common-styles
.section-padding {
  padding-left: 63px;
  padding-right: 30px;
}

.section-common-heading {
  font-size: 1.2rem;
  font-family: $RobotoBold;
  color: $black;
  // letter-spacing: 0.5px;
}

.common-sub-header {
  font-size: 1rem;
  font-family: $RobotoSemiBold;
  color: $black;
  font-weight: 500;
}
.ngx-toastr {
  width: 400px !important; // Specify toastr-dialog width for all devices
  top: 120px;
}

.bold-detailed-text {
  font-size: 1.125rem;
  line-height: 26px;
  font-family: $RobotoBold;
}

.reg-text-style {
  font-size: 1.125rem;
  line-height: 26px;
  font-family: $RobotoReg;
}

.secondary-btn-styles {
  font-size: 0.9rem;
  color: $colorGrey;
  padding: 10px;
  line-height: 16px;
  // letter-spacing: 1.25px; //Commented as of there is so much space between letters
  text-transform: uppercase;
  cursor: pointer;
  font-family: $RobotoBold;
  background-color: inherit !important;
  border: none;
  &:hover {
    background-color: inherit !important;
  }
  @include layout(767) {
    font-size: 0.75rem;
    padding: 7px 15px;
  }
  &:focus-visible {
    outline: unset !important;
    outline: none !important;
  }
}

/** Common styles for enabled buttons all over the application */
.btn-enabled {
  box-shadow: unset;
  padding: 10px 20px;
  line-height: inherit;
  font-size: 0.9rem;
  color: white;
  background-color: $btnColor;
  text-transform: uppercase;
  font-family: $RobotoBold;
  letter-spacing: 0.25px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  @include layout(767) {
    padding: 7px 15px;
    font-size: 0.75rem;
  }
}

/** Common styles for disabling a button all over the application */
.btn-disabled {
  cursor: unset;
  filter: grayscale(1);
  pointer-events: none;
  opacity: 0.5;
}

.btn-create {
  padding: 10px 15px 10px 15px;
  color: $colorGrey;
  font-family: $RobotoBold;
  font-size: 1rem;
  position: relative;
  border: 2px solid $black;
  border-radius: 5px;
  text-transform: uppercase;
  line-height: inherit;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-create-icon {
    vertical-align: middle;
    @include layout(767) {
      max-height: 12px;
    }
    img {
      width: 17px;
      height: 17px;
      margin-left: 15px;
      margin-bottom: -2px;
      @include layout(767) {
        width: 12px;
        height: 12px;
      }
    }
  }

  @include layout(1023) {
    padding: 10px;
    padding-right: 20px;
    font-size: 0.9rem;
    @include layout(767) {
      padding-right: 10px;
      padding: 7px 5px;
      font-size: 0.75rem;
    }
  }
}

// slider toggle button styles
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #23a4ad;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(35, 164, 173, 0.35);
}

// slider toggle button styles ends

// slider bar styles
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: $btnColor;
  border: none;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px;
}

.mat-accent .mat-slider-thumb {
  height: 29px;
  width: 29px;
  background-color: $sliderGray;
  border: solid 2px $sliderGray;
  bottom: -20px;
  right: -22px;
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing)
  .mat-slider-thumb {
  background-color: $btnColor;
}

.mat-slider-track-background {
  height: 4px !important;
  top: 5px !important;
  background-color: $sliderGray;
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: $sliderGray;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 50%;
  top: 4px;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.mat-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb,
.mat-slider:not(.mat-slider-disabled).cdk-touched-focused .mat-slider-thumb,
.mat-slider:not(.mat-slider-disabled).cdk-program-focused .mat-slider-thumb {
  transform: scale(0.7) !important;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  background-color: #ffd740 !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb-container:focus-visible {
  transform: scale(4) !important;
}

// mat slider styles ends

// mat select styles
.mat-form-field-underline {
  display: none;
}

.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.19);
  // pointer-events: none;
}
::ng-deep {
  .action-dropdown {
    .cdk-overlay-container {
      .cdk-overlay-pane {
        height: 0px !important;
      }
    }
  }
}
///cdk action-dropdown-styles
///
// ::ng-deep {
//   .cdk-overlay-container {
//     .cdk-overlay-backdrop {
//       .cdk-overlay-connected-position-bounding-box {
//         .cdk-overlay-pane {
//           height: 0px !important;
//         }
//       }
//     }
//   }
// }
// #cdk-overlay-0 {
//   height: 0px !important;
// }
// #cdk-overlay-1 {
//   height: 0px !important;
// }
// #cdk-overlay-2 {
//   height: 0px !important;
// }
// #cdk-overlay-3 {
//   height: 0px !important;
// }

.cdk-overlay-container {
  .cdk-overlay-pane {
    .common_scroll-bar {
      // width: 5px;
      // overflow-y: scroll;
      scrollbar-color: #f1f1f1;
      border-radius: 8px;
      scrollbar-width: thin !important;
    }

    .common_scroll-bar::-webkit-scrollbar {
      width: 5px;
      height: 4px;
    }

    /* Track */
    .common_scroll-bar::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .common_scroll-bar::-webkit-scrollbar-thumb {
      background: $scrollBg;
      border-radius: 8px;
    }

    /* Handle on hover */
    .common_scroll-bar::-webkit-scrollbar-thumb:hover {
      background: $scrollBg;
      width: 8px;
    }

    .mat-dialog-container::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .mat-dialog-container::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .mat-dialog-container::-webkit-scrollbar-thumb {
      background: $scrollBg;
      border-radius: 8px;
    }

    /* Handle on hover */
    .mat-dialog-container::-webkit-scrollbar-thumb:hover {
      background: $scrollBg;
    }

    div.mat-autocomplete-panel {
      mat-option.mat-option-disabled {
        color: rgba(0, 0, 0, 0.38);
        pointer-events: none;
      }
    }

    .mat-select-filter {
      mat-option {
        .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
          background-color: $sheenColor;
        }
      }
    }
    .mat-select-filter-chamber {
      mat-option {
        .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
          background-color: $sheenColor;
        }
      }
    }
    // .mat-select-filter-chamber::before{
    //   font-family: 'Material Icons';
    // content: '\00d7';
    // font-size:40px;
    // margin-left:335px;
    // cursor: pointer;
    // }

    .select_month-dropdown-value-align {
      left: 16px;
      position: relative;
      min-width: 117px !important;
    }

    .mat-select-panel-wrap {
      // height: 0px;
      .action-dropdown-select {
        min-width: inherit !important;
        position: relative;
        // top: 45px;
        // @include layout(1023) {
        //   // top: 40px;
        //   left: 14%; ///Need to change affecting sub-header
        //   width: 133px;
        //   top: 26px;
        // }

        .mat-option .mat-active {
          background: unset !important;
          // color: unset;
        }

        mat-option.action-dropdown-tooltip {
          pointer-events: stroke;
          background: rgb(209, 212, 217, 0.7) !important;
          mat-icon {
            color: $colorGrey;
            opacity: 0.5;
          }
        }
      }
    }

    .select_days_graphs {
      font-size: 0.85rem;
    }
    .sub-header-mat-select,
    .action-dropdown-select {
      min-width: inherit !important;
      width: 216px;
      position: relative;
      top: 12px;
      left: 2px;

      mat-option {
        &:hover {
          background: $matOptionHoverColor !important;
        }
      }

      mat-option.action-selected {
        background: $sparklingForst !important;
        // color: white !important;

        // .mat-option-text{
        //   .mat-icon {
        //     color: white !important;
        //   }
        // }
      }

      mat-option.action-disabled {
        background: rgb(209, 212, 217, 0.7) !important;
        pointer-events: none;
        mat-icon {
          color: $colorGrey;
          opacity: 0.5;
        }
      }

      mat-option.mat-active {
        background: $commanWhite;
      }

      @include layout(1023) {
        // top: 40px;
      }
    }
    .ppu-model-note {
      margin-top: 30px;
    }

    .successmsg-container {
      .body-div-text {
        .delete_cluster-popup {
          display: flex;
          gap: 10px;
        }
      }
    }

    .sub-header-mat-select {
      width: 378px !important;
      max-width: 378px;
      top: 36px;
      left: 4px;
      @include layout(767) {
        top: 29px;
        border-bottom: 1px solid #80808087;
      }
      mat-option:last-child {
        border-bottom: 1px solid #80808087;
      }
    }
    .reporting-mat-select {
      width: 356px;
    }

    .deployment-drop-down {
      position: relative;
      top: 28px;
      @include layout(1023) {
        // top: 40px;
      }
    }
    .service-deployment-drop-down {
      left: 6px;
    }

    .success-popup,
    .error-popup {
      // position: absolute;
      // top: 100px;
      margin-top: 20%;
      // left: 778px;
    }
  }
}

//action dropdown scroll common styles

.mat-autocomplete-panel {
  width: 100%;
  height: auto;
  // scrollbar-color: #f1f1f1;
  border-radius: 8px !important;
  scrollbar-width: thin !important;
  overflow-y: auto;
}
.mat-autocomplete-panel:-webkit-scrollbar {
  width: 5px;
  border-radius: 8px !important;
  // height: 4px;
}

/** Track */
.mat-autocomplete-panel:-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px !important;
}

/** Handle */
.mat-autocomplete-panel:-webkit-scrollbar-thumb {
  background: $scrollBg;
  border-radius: 6px !important;
}

// Common Scroll Bar Styles firefox popups
.mat-dialog-container {
  // width: 5px;
  // overflow-y: scroll;
  width: 100%;
  height: auto;
  // scrollbar-color: #f1f1f1;
  border-radius: 8px !important;
  scrollbar-width: thin !important;
  overflow-y: auto;
}
/** Width */
.mat-dialog-container:-webkit-scrollbar {
  width: 5px;
  border-radius: 8px !important;
  // height: 4px;
}

/** Track */
.mat-dialog-container:-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px !important;
}

/** Handle */
.mat-dialog-container:-webkit-scrollbar-thumb {
  background: $scrollBg;
  border-radius: 6px !important;
}
//reporting screen common scroll

.mat-select-panel {
  // width: 5px;
  // overflow-y: scroll;
  width: 100%;
  height: auto;
  // scrollbar-color: #f1f1f1;

  scrollbar-width: thin !important;
  overflow-y: auto;
}

/** Width */
.mat-select-panel:-webkit-scrollbar {
  width: 5px;
  border-radius: 8px !important;
  // height: 4px;
}

/** Track */
.mat-select-panel:-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px !important;
}

/** Handle */
.mat-select-panel:-webkit-scrollbar-thumb {
  background: $scrollBg;
  border-radius: 6px !important;
}

.common_scroll-bar-mozilla {
  // width: 5px;
  // overflow-y: scroll;
  width: 100%;
  height: auto;
  // scrollbar-color: #f1f1f1;
  border-radius: 8px !important;
  scrollbar-width: thin !important;
  overflow-y: auto;
}

/** Width */
.common_scroll-bar-mozilla:-webkit-scrollbar {
  width: 5px;
  border-radius: 8px !important;
  // height: 4px;
}

/** Track */
.common_scroll-bar-mozilla:-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px !important;
}

/** Handle */
.common_scroll-bar-mozilla:-webkit-scrollbar-thumb {
  background: $scrollBg;
  border-radius: 6px !important;
}

/** Common Scroll Bar Styles */
.common_scroll-bar {
  // width: 5px;
  // overflow-y: scroll;
  width: 100%;
  height: auto;
  // scrollbar-color: #f1f1f1;
  border-radius: 8px;
  scrollbar-width: thin !important;
  overflow-y: auto;
}

/** Width */
::-webkit-scrollbar {
  width: 5px;
  // height: 4px;
}

/** Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/** Handle */
::-webkit-scrollbar-thumb {
  background: $scrollBg;
  border-radius: 8px;
}

/** Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scrollBg;
  width: 8px;
}

.mat-dialog-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.mat-dialog-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mat-dialog-container::-webkit-scrollbar-thumb {
  background: $scrollBg;
  border-radius: 8px;
}

/* Handle on hover */
.mat-dialog-container::-webkit-scrollbar-thumb:hover {
  background: $scrollBg;
}

// mat select styles end

// input type number remove default styles
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input:focus,
:focus-visible {
  outline: none;
}
// input type number remove default styles end

// common fonts styles
.font_16 {
  font-size: $fs + -2;
}

.font_18 {
  font-size: $fs;
}

.font_075rem {
  font-size: 0.75rem;
}

.font_09rem {
  font-size: 0.9rem;
  @include layout(1023) {
    font-size: 0.75rem;
  }
}
.font_85rem {
  font-size: 0.85rem !important;
}

.font_20 {
  font-size: $fs + 2;
}

.font_24 {
  font-size: $fs + 6;
}
.font_10rem {
  font-size: 1rem;
  @include layout(1023) {
    font-size: 0.9rem;
  }
}
.font_32 {
  font-size: $fs + 14;
}
.font_11rem {
  font-size: 1.1rem;
  @include layout(1023) {
    font-size: 1rem;
  }
}

.font_40 {
  font-size: ($fs + -10) * 5;
}
.font_12rem {
  font-size: 1.2rem;
  @include layout(1023) {
    font-size: 1.1rem;
  }
}

.font_50 {
  font-size: $fs + 32;
}
.font_13rem {
  font-size: 1.3rem;
}
.font_bold {
  font-family: $RobotoBold;
}

.font_reg {
  font-family: $RobotoReg;
  color: $black;
}

.font_grey {
  color: $colorGrey;
}
.font_black {
  color: $commonBlack;
}
.font_darkGray {
  color: $gunPowder;
}
.font_voilet {
  color: $btnColor;
}
.font_white {
  color: white;
}
.font_blue {
  color: $commonBlue;
}
.font_err {
  color: $errorColor;
}
.font_red {
  color: $redColor;
}
.font_success {
  color: $greenColor;
}
// common fonts styles
a {
  text-decoration: none;
  color: unset;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// checkox styles
.checkbox-block {
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 4px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $sheenColor;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 1px;
    width: 3px;
    height: 9px;
    border: solid white;
    border-width: 0 2.5px 2.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// checkox styles
.no-wrap {
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}
.pointer-none {
  pointer-events: none;
}
.default {
  cursor: default;
}
// flex styles

.align-center {
  display: flex;
  align-items: center;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-space-btw {
  display: flex;
  justify-content: space-between;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.disp-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.colmn_align-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.margin-auto {
  margin-left: auto;
  margin-right: auto;
}
.disp-none {
  display: none;
}
.full_height {
  height: 100%;
}
// flex styles

// .highcharts-legend-item rect {
//   // display: none;
//   // color: #59b7534d;
// }

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.confirm_popup-text {
  margin-bottom: 20px;
}
.my-custom-snackbar {
  margin: 0 !important;
  position: absolute;
  right: 25px;
  top: 60px;
}

// To remove default resize option for textarea
textarea {
  resize: none;
}
// To remove default resize option for textarea

// radio button styles
.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 15px;
  height: 15px;
  border-radius: 11px;
  border: 2px solid $sheenColor;
  background-color: transparent;
}
.radio-item input[type='radio']:not(:checked) + label:before {
  border: 2px solid #666;
}
.radio-item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 9.5px;
  left: 10.4px;
  content: ' ';
  display: block;
  background: $sheenColor;
}
// radio button styles

// To remove the hover border color to the fieldset
::ng-deep {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $borderColor !important;
  }
  .mat-form-field-appearance-outline
    .mat-form-field-outline-thick
    .mat-form-field-outline-start,
  .mat-form-field-appearance-outline
    .mat-form-field-outline-thick
    .mat-form-field-outline-end,
  .mat-form-field-appearance-outline
    .mat-form-field-outline-thick
    .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
  //hover issue with the edge browser
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-radius: 0px !important;
  }
}

//filter button styles
.filter-button {
  background-color: $btnColor;
  padding: 7px;
  border-radius: 5px;
  color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $RobotoReg;
  .filter-text {
    margin-left: 12px;
    margin-right: 12px;
  }
  .filter-number {
    background-color: white;
    border-radius: 50%;
    color: $btnColor;
    padding: 2px 6px;
    font-size: 0.75rem;
  }
}
.selected-filter {
  background: #e6eaed;
  padding: 9px 15px 10px 10px;
  margin-left: 20px;
  border-radius: 5px;
  .close {
    height: fit-content;
    width: fit-content;
  }
}
// To remove the hover border color to the fieldset

::ng-deep {
  .cdk-global-scrollblock {
    top: 0px !important;
  }
  .disabled_field {
    .mat-input-element {
      color: $fordGray;
      cursor: default;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: #646e82e6 !important; //R4.3 Change - Increased the opacty for the disabled fields
    }
  }

  // tooltip styles
  .mat-tooltip {
    font-size: 0.9rem !important;
    background-color: black;

    &::after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid black;
    }
    &.below {
      overflow: initial;
      margin-top: 1rem;
      &:after {
        top: -0.5rem;
        right: calc(50% - 0.5rem);
        transform: rotate(0);
      }
    }
    &.above {
      overflow: initial;
      margin-bottom: 1rem;
      &:after {
        bottom: -0.5rem;
        right: calc(50% - 0.5rem);
        transform: rotate(180deg);
      }
    }
    &.right {
      overflow: initial;
      margin-left: 1rem;
      &:after {
        left: -0.75rem;
        top: calc(50% - 0.25rem);
        transform: rotate(270deg);
      }
    }
    &.left {
      overflow: initial;
      margin-right: 1rem;
      &:after {
        right: -0.75rem;
        top: calc(50% - 0.25rem);
        transform: rotate(90deg);
      }
    }
    // white-space: nowrap;
  }
}
.bg_grey {
  background-color: $ghostWhite !important;
}
.paginator-wrapper,
.mat-paginator {
  background-color: $ghostWhite;
  font-size: 0.875rem;
}

// style for ellipses
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled-field {
  // opacity: 0.4;
  opacity: 0.9; //R4.3 Change -- Changed the opacity to 0.9 all over the disabled fields
  pointer-events: none;
}

.login_link {
  color: $sheenColor !important;
  text-decoration: underline !important;
}
.mat_info-icon {
  color: #5a2a82;
}

.margin-right10 {
  margin-right: 10px;
}
.margin-right30 {
  margin-right: 30px;
}
//discriptive text fonts
.descriptive_text-font-styles {
  font-size: 1rem !important;
  font-family: 'roboto-regular' !important;
  font-weight: 400 !important;
  pointer-events: auto;
  @include layout(1200) {
    font-size: 0.95rem !important;
  }
}

::ng-deep {
  /** Common style for the mat-auto complete fields */
  .input-fields {
    .mat-form-field {
      .mat-form-field-wrapper {
        width: 250px; // when needed more height and width can be changed across the component scss
        height: 50px !important;
        .mat-form-field-flex {
          .mat-form-field-outline,
          .mat-form-field-outline-start,
          .mat-form-field-outline-end {
            border-radius: 0px 0 0 0px;
            background: $commanWhite;
          }
        }
        .mat-form-field-infix {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  /** Common styles for the search field across the application */
  .find_users-field {
    border: 1px solid $fordGray;
    background-color: white;
    width: 350px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      padding: 15px;
      border: none;
      width: 100% !important;
      // border-top: 1px solid $fordGray;
      @include layout(1023) {
        width: 150px; // when needed more height and width can be changed across the components scss
        @include layout(767) {
          width: 130px;
          padding: 7px;
          &::placeholder {
            font-size: 0.875rem;
          }
        }
      }
    }
    .search-icon {
      // position: absolute;
      // top: 13px;
      // right: 0;
      padding: 0 10px 0 0;
      color: $black;
    }

    .focused-icon {
      color: $commonBlue;
      // position: relative;
      // top: 13px;
      padding: 0 10px 0 0;
    }
  }

  /** Styles for the terms and condition popup */
  .terms-condition-popup {
    mat-dialog-container.mat-dialog-container {
      padding: 24px 17px 24px 24px;
    }
  }
}

.app-main-heading {
  font-size: 1.2rem !important;
  font-weight: 700;
}

.app-sub-heading {
  font-size: 1.1rem !important;
  font-weight: 600;
}

.app-sectional-heading {
  font-size: 1rem !important;
  font-weight: 500;
}

.app-sectional-sub-heading {
  font-size: 0.9rem !important;
  font-weight: 400;
}

// ::ng-deep .mat-form-field-required-marker {
//   color: red !important;
//   background-color: ghostwhite !important;
//   padding-left: 7px;
// }
.color--red {
  color: red !important;
}

::ng-deep .mat-form-field-label-wrapper {
  .mat-form-field-label {
    display: flex !important;
    flex-direction: row-reverse;
    gap: 5px;
    left: 5px !important;
    width: max-content !important;
    // z-index: 9999;
    // background-color: white;
    padding-left: 4px;
  }
}

// refresh icon styles
.refresh-button {
  margin-right: 12px;
}

.rotate-icon {
  animation: rotation 0.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// common cancel button styles
.cancel-btn {
  padding-top: 20px;
  color: #5a2a82;
  border: none;
  background-color: white;
  font-family: $RobotoBold;
  font-size: $fs + -2;
  cursor: pointer;
  @include layout(1023) {
    font-size: 0.875rem;
    padding: 10px;
  }
}
/** Common Styles for Font-weight-500 Boldness for Roboto Bold font-family*/
.font-weight__500--bold {
  font-weight: 500;
  font-family: $RobotoBold;
  font-size: 1rem !important;
}

.mainBody {
  display: flex;
  justify-content: space-between;

  .mainBody__close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;

    .close-icon {
      color: black;
    }
  }
}

.font_normal {
  font-weight: 400 !important;
  font-family: 'Roboto-Regular' !important;
}

.display--flex {
  display: flex;
}

.align--baseline {
  align-items: baseline;
}

.margin-left10 {
  margin-left: 10px;
}

::ng-deep .mat-button-toggle-group {
  .mat-button-toggle-appearance-standard {
    background-color: $paleGrey;
  }
}
// mat-loader-common styles
.mat-spinner {
  circle {
    stroke: #808080 !important;
  }
}

.position--relative {
  position: relative;
}

::ng-deep {
  .mat-checkbox-persistent-ripple {
    background-color: $sheenColor !important;
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background-color: $sheenColor !important;
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background-color: $sheenColor !important;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $sheenColor !important;
  }
  .mat-button-base.cdk-program-focused .mat-button-focus-overlay {
    display: none;
  }
}

// mat- select dropdown common styles

::ng-deep {
  .width-mat-common-select-dropdown {
    @include layout(900) {
      max-width: 240px !important;
    }
  }
  .common-mat-select-dropdown {
    margin-top: 30px;
    margin-left: 7px;
  }
  .common-mat-select-dropdown-eda-csl {
    margin-top: 34px;
    margin-left: 0px;
  }
  .common-mat-select-dropdown-settings {
    margin-top: 34px;
    margin-left: 5px;
  }
  .common-filter-dropdown {
    margin-top: 25px;
    min-width: calc(100% + 28px) !important;
    margin-left: 11px;
  }

  .active-chipspot-popup-panel {
    .mat-dialog-container {
      padding-right: 0px !important;
    }
  }
}

//chip-spot scroll bar

.terms-and-condition-body {
  // border-radius: 8px !important;

  scrollbar-width: thin !important;
}

.terms-and-condition-body:-webkit-scrollbar {
  width: 5px;

  border-radius: 8px !important;
}

/** Track */

.terms-and-condition-body:-webkit-scrollbar-track {
  background: #f1f1f1;

  border-radius: 8px !important;
}

/** Handle */

.terms-and-condition-body:-webkit-scrollbar-thumb {
  background: $scrollBg;

  border-radius: 6px !important;
}

.breadcrumb-section {
  .breadcrumb-text {
    text-transform: uppercase;
  }
}
.mat-option {
  font-size: 1rem;
}
//sort icon disabling
::ng-deep {
  .mat-sort-header-arrow {
    display: none !important;
  }
  .mat-sort-header-content {
    cursor: pointer;
  }
  .paginator-wrapper {
    max-width: 1820px;
  }
}
fse-snps-webapp-root{
  width:100%;
  height:100%;
  overflow:hidden;
  display:flex;
  flex-direction: column;
}
fse-admin{
  width:100%;
  height:100%;
  overflow:hidden;
}

@media screen and (max-width: 767px) {
  html,body {
    overflow: auto;
  }
  fse-snps-webapp-root,fse-admin{
    overflow:visible;
  }
}

.primary-button-bg{
  background-color: rgb(59, 130, 246,1)!important;
}
.primary-button-bg:disabled{
  background-color: rgb(188, 188, 188)!important;
}
